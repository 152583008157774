<template>
	<b-card no-body>
		<b-card-body class="py-0">
			<!-- Address -->
			<h2 class="mb-4" style="font-size: 1.2rem">
				<img src="./../../assets/img/logo_doconomy.svg" alt="Logo doconomy" width="120" class="mt-3" />
			</h2>
			<b-row>
				<b-col sm="6" md="6">
					<b-row>
						<b-col sm="4" md="4">
							<label>{{ FormMSG(2, 'Master category') }}</label>
						</b-col>
						<b-col sm="8" md="8">
							<b-form-select v-model="selectedMasterCategory" :options="doconomyMasterCategories" @change="makeCategories" />
						</b-col>
					</b-row>
				</b-col>
				<b-col sm="6" md="6">
					<b-row>
						<b-col sm="4" md="4">
							<label>{{ FormMSG(3, 'Category') }}</label>
						</b-col>
						<b-col sm="8" md="8">
							<b-form-select v-model="selectedCategory" :options="doconomyCategories" @change="emitEvent" />
						</b-col>
					</b-row>
				</b-col>
			</b-row>
			<b-row class="mt-5">
				<b-col>
					<div class="float-right" style="font-size: 14px">
						{{ FormMSG(44, 'Provided by Doconomy') }}
					</div>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import LogoDoconomySVG from '@/assets/img/logo_doconomy.svg';

export default {
	name: 'InputSelectDoconomy',

	components: {
		LogoDoconomySVG
	},

	mixins: [languageMessages],

	props: {
		doconomyCategoryParams: {
			type: [Number, String],
			required: false,
			default: 0
		}
	},

	data() {
		return {
			doconomyMasterCategories: [],
			doconomyCategories: [],
			selectedMasterCategory: null,
			selectedCategory: null,
			listDoconomy: []
		};
	},

	watch: {
		async doconomyCategoryParams(val) {
			if (parseInt(val) > 0) {
				await this.makeMasterCategoriesWithProps();
			} else {
				this.doconomyCategories = [
					{
						value: null,
						text: this.FormMSG(6, 'Please select an master category')
					}
				];
				this.selectedMasterCategory = null;
				this.selectedCategory = null;
			}
		}
	},

	// async beforeCreate() {
	//   await this.getDoconomyList()
	// },

	async mounted() {
		await this.getDoconomyList();
		this.doconomyCategories.push({
			value: null,
			text: this.FormMSG(6, 'Please select an master category')
		});
		await this.makeMasterCategories();

		if (parseInt(this.doconomyCategoryParams) > 0) {
			await this.makeMasterCategoriesWithProps();
		}
	},

	methods: {
		async getDoconomyList() {
			try {
				const query = gql`
					query GetDoconomyCategoryList {
						GetDoconomyCategoryList {
							id
							category
							masterCategory
							categoryName
							masterCategoryName
							coTwoEur
						}
					}
				`;
				const {
					data: { GetDoconomyCategoryList }
				} = await apollo.query({
					query,
					fetchPolicy: 'no-cache'
				});

				this.listDoconomy = GetDoconomyCategoryList;
			} catch (e) {
				console.log(e.message);
			}
		},

		makeMasterCategories() {
			this.doconomyMasterCategories.push({
				value: null,
				text: this.FormMSG(5, 'Please select an option')
			});

			this.listDoconomy.forEach((list) => {
				if (this.doconomyMasterCategories.find((f) => f.text === list.masterCategoryName) === undefined) {
					this.doconomyMasterCategories.push({
						text: list.masterCategoryName,
						value: list.masterCategory
					});
				}
			});
		},

		makeMasterCategoriesWithProps() {
			const index = this.listDoconomy.findIndex((l) => l.category === parseInt(this.doconomyCategoryParams));
			this.selectedMasterCategory = this.listDoconomy[index].masterCategory;
			this.makeCategories(this.selectedMasterCategory, parseInt(this.doconomyCategoryParams));
		},

		makeCategories(payload, docCategory = 0) {
			this.doconomyCategories = [];

			if (payload) {
				const filter = this.listDoconomy.filter((list) => list.masterCategory === payload);
				this.selectedCategory = docCategory > 0 ? docCategory : filter[0].category;
				this.emitEvent(filter[0].category);
				filter.forEach((f) => {
					this.doconomyCategories.push({
						text: f.categoryName,
						value: f.category
					});
				});
			} else {
				this.doconomyCategories.push({
					value: null,
					text: this.FormMSG(6, 'Please select an master category')
				});
				this.selectedCategory = null;
				this.emitEvent(null);
			}
		},

		emitEvent(payload) {
			if (payload) {
				this.$emit('select:category', {
					doconomyCategory: this.selectedCategory
				});
			} else {
				this.$emit('select:category', {
					doconomyCategory: null
				});
			}
		}
	}
};
</script>

<style scoped></style>
