var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-body",
        { staticClass: "py-0" },
        [
          _c(
            "h2",
            { staticClass: "mb-4", staticStyle: { "font-size": "1.2rem" } },
            [
              _c("img", {
                staticClass: "mt-3",
                attrs: {
                  src: require("./../../assets/img/logo_doconomy.svg"),
                  alt: "Logo doconomy",
                  width: "120",
                },
              }),
            ]
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "6", md: "6" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "4", md: "4" } }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.FormMSG(2, "Master category"))),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "8", md: "8" } },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.doconomyMasterCategories },
                            on: { change: _vm.makeCategories },
                            model: {
                              value: _vm.selectedMasterCategory,
                              callback: function ($$v) {
                                _vm.selectedMasterCategory = $$v
                              },
                              expression: "selectedMasterCategory",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "6", md: "6" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { sm: "4", md: "4" } }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.FormMSG(3, "Category"))),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        { attrs: { sm: "8", md: "8" } },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.doconomyCategories },
                            on: { change: _vm.emitEvent },
                            model: {
                              value: _vm.selectedCategory,
                              callback: function ($$v) {
                                _vm.selectedCategory = $$v
                              },
                              expression: "selectedCategory",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-5" },
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass: "float-right",
                    staticStyle: { "font-size": "14px" },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(44, "Provided by Doconomy")) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }